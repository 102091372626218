import React, { useState } from "react";
import { Box, Typography, Paper, IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function CommandBox({ text, command, textSecondary }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(command)
      .then(() => {
        setOpenSnackbar(true); // Show the snackbar
      })
      .catch((error) => {
        console.error("Failed to copy command:", error);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Paper sx={{ p: 3, width: "48%", borderRadius: 4, boxShadow: 0 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {textSecondary}
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        bgcolor="#F8F9FA"
        p={1}
        borderRadius={2}
      >
        <Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>
          {command}
        </Typography>
        <IconButton onClick={handleCopy}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* Snackbar for copy confirmation */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000} // Duration in milliseconds
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Paper>
  );
}

export default CommandBox;
