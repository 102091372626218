import React from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import Sidebar from "../components/common/sideBar";
import TopBar from "../components/images/topBar";
import ImageGrid from "../components/images/imageGrid";

function Images() {
  return (
    <div style={{ display: "flex", background: "#F8F9FA" }}>
      <CssBaseline />
      <Box
        display="flex"
        sx={{
          minHeight: "100vh",
          minWidth: "100%",
        }}
      >
        <Sidebar />
        <Container
          sx={{
            mt: "20px",
            background: "rgb(255, 255,255)",
            borderRadius: 4,
            ml: 4,
            mr: 4,
            mb: 4,
            maxWidth: "none !important",
            p: 2,
          }}
        >
          <TopBar />

          <ImageGrid />
        </Container>
      </Box>
    </div>
  );
}

export default Images;
