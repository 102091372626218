import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function BreadcrumbNav({ onBack }) {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <IconButton onClick={onBack} sx={{ color: "#A0AEC0" }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography
        onClick={onBack}
        variant="body2"
        color="#A0AEC0"
        fontWeight={500}
        sx={{ mx: 1, cursor: "pointer" }}
      >
        Image Repository
      </Typography>
      <Typography
        fontWeight={500}
        variant="body2"
        color="textSecondary"
        sx={{ mx: 0.5 }}
      >
        /
      </Typography>
      <Typography fontWeight={500} variant="body2" color="textPrimary">
        Node JS
      </Typography>
    </Box>
  );
}

export default BreadcrumbNav;
