import React from "react";
import { Box, Typography, Container, Button } from "@mui/material";
// import plus sign
import AddIcon from "@mui/icons-material/Add";

const TopBar = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={4}
      pl={0}
    >
      <Container
        disableGutters
        sx={{
          ml: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 500,
          }}
        >
          Image Repository
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#A0AEC0",
          }}
        >
          {" "}
          Don’t find an image you want? Request an image and our team with work
          on it right away.
        </Typography>
      </Container>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#4FD1C5",
          color: "white",
          borderRadius: 2,
          padding: "10px 20px",
          mr: 4,
          fontWeight: 500,
          boxShadow: "none",
        }}
      >
        {" "}
        <AddIcon sx={{ mr: 1, fill: "#FFF" }} />
        Request Image
      </Button>
    </Box>
  );
};

export default TopBar;
