import React from "react";
import { Card, CardContent, Typography, Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import nodejs from "../../assets/images/imageIcons/nodejs.png";
import php from "../../assets/images/imageIcons/php.png";
import postgres from "../../assets/images/imageIcons/postgres.png";
import python3 from "../../assets/images/imageIcons/python.png";

const getImagePath = (name) => {
  const serviceName = name.split("/")[0];

  const imageMap = {
    nodejs: nodejs,
    php: php,
    postgres: postgres,
    python3: python3,
  };

  return imageMap[serviceName];
};

const extractName = (name) => {
  return name.split("/")[1];
};

const ImageCard = ({ name, FPIS, STIG, id }) => {
  const navigate = useNavigate();
  const image = getImagePath(name);
  const handleClick = () => {
    navigate(`/image-details/${id}`);
  };

  return (
    <Card
      sx={{
        padding: 2,
        minHeight: 200,
        cursor: "pointer",
        background: "#FFF",
        boxShadow: "0px 4px 36px 0px rgba(45, 55, 72, 0.05)",
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Box
            component="img"
            src={image}
            alt={name}
            sx={{ height: 65, width: 65, borderRadius: 1, mr: 2 }}
          />
          <Box display="flex" flexDirection="column">
            <Typography
              variant="h6"
              fontWeight="bold"
              color="text.primary"
              gutterBottom
            >
              {extractName(name)}
            </Typography>
            <Box display="flex" justifyContent="left" gap={1}>
              {FPIS && (
                <Chip
                  label="FPIS"
                  sx={{
                    backgroundColor: "#E3EFFF",
                    color: "#4B89FF",
                    fontWeight: "bold",
                    borderRadius: 10,
                    paddingX: "6px",
                    height: "28px",
                  }}
                />
              )}
              {STIG && (
                <Chip
                  label="STIG"
                  sx={{
                    backgroundColor: "#FFECE9",
                    color: "#FF6B6B",
                    fontWeight: "bold",
                    borderRadius: 10,
                    paddingX: "6px",
                    height: "28px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
              Last Updated:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              3 hours ago
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
              Latest Version:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {extractName(name) == "nodejs" ? "22" : ""}
              {extractName(name) == "php" ? "8.2" : ""}
              {extractName(name) == "postgres" ? "15" : ""}
              {extractName(name) == "python3" ? "3.11" : ""}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImageCard;
