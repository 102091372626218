import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  Collapse,
} from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import Version from "../../assets/images/version_tag.svg";
import StatusChips from "./StatusChips";
import axios from "axios";
import download from "../../assets/images/download.svg";
import { saveAs } from 'file-saver';

function formatDate(updatedAt) {
  const date = new Date(updatedAt);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = String(date.getUTCFullYear()).slice(-2);

  return `${year}-${month}-${day}`;
}

function formatTime(updatedAt) {
  const date = new Date(updatedAt);

  date.setMinutes(date.getMinutes() + 330); // Adding 330 minutes for GMT+5:30

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  return `${String(hours).padStart(2, "0")}:${minutes} ${period} GMT + 5:30`;
}


function Vulnerabilities({ versions }) {
  const BUILD_SERVER_FILE_URL = "http://40.90.225.33:3000/files";
  const [search, setSearch] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].version,
  );
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [displayedVulnerabilities, setDisplayedVulnerabilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPanel, setOpenPanel] = useState(null);
  const [slug, setSlug] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].slug,
  );
  useEffect(() => {
    const fetchVulnerabilities = async () => {
      const selectedVersionData = versions.find(
        (v) => v.version === selectedVersion,
      );

      if (selectedVersionData && selectedVersionData.id) {
        setLoading(true);
        try {
          const response = await axios.get(
            `/api/data/image/${selectedVersionData.id}/vulns`,
          );
          setVulnerabilities(response.data["vulns"]
              .filter(
                (vuln, index) =>
                  vuln?.severity !== "Negligible" && index != undefined,
              )
          );

          console.log(response.data);
        } catch (err) {
          console.log("Failed to fetch vulnerabilities.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVulnerabilities();
  }, [selectedVersion, versions]);

  useEffect(() => {
    if (search.trim() == "") {
      setDisplayedVulnerabilities(vulnerabilities);
    } else {
      const lowercasedSearch = search.toLowerCase();
      setDisplayedVulnerabilities(
        vulnerabilities.filter(
          (row) =>
            row.CVE.toLowerCase().includes(lowercasedSearch) ||
            row.packageName.toLowerCase().includes(lowercasedSearch),
        ),
      );
    }
  }, [search, vulnerabilities]);
  
  const handlePanelToggle = (index) => {
    setOpenPanel(openPanel === index ? null : index);
  };

  async function handleDownload() {
    try {
      const response = await axios.get(
        `${BUILD_SERVER_FILE_URL}/${slug.split(':')[0]}_${selectedVersion}/vulnerabilities.json`,
        { responseType: 'blob' } // Important for file downloads
      );
      const fileName = 'vulnerabilities.json'; // Set your desired file name
      saveAs(response.data, fileName);
      console.log('File downloaded successfully');
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }


  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <Select
            renderValue={(value) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Version}
                  alt="version"
                  style={{ marginRight: "0.5rem" }}
                />
                <span style={{ color: "#A0AEC0" }}>Version:</span>
                <span style={{ color: "black", marginLeft: "0.3rem" }}>
                  {value}
                </span>
              </div>
            )}
            onChange={(e) => setSelectedVersion(e.target.value)}
            displayEmpty
            value={selectedVersion}
            variant="outlined"
            sx={{ borderRadius: 2, pr: 3 }}
          >
            {[
              ...new Set(
                versions
                  .filter((row) => row.arch == "amd64 + arm64")
                  .map((v) => v.version),
              ),
            ].map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            placeholder="Search CVE"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ fill: "#A0AEC0" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "16px",
              },
            }}
          />
        </div>
        <Button
          variant="contained"
          onClick={handleDownload}
          sx={{
            backgroundColor: "#4FD1C5",
            color: "white",
            fontWeight: 600,
            marginBottom: 2,
            boxShadow: 0,
          }}
        >
          <img
            src={download}
            alt="download"
            style={{ marginRight: "0.5rem" }}
          />
          DOWNLOAD Vulnerabilities
        </Button>
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                  width: "10px",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  CVE ID
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  SEVERITY
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  PACKAGE
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  VERSION
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                {" "}
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  LAST CHANGED
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1">Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : displayedVulnerabilities.length > 0 ? (
              displayedVulnerabilities
                // .filter(
                //   (vuln, index) =>
                //     vuln?.severity !== "Negligible" && index != undefined,
                // )
                .map((vuln, index) => (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Button onClick={() => handlePanelToggle(index)}>
                          {openPanel === index ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          {vuln.CVE}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <StatusChips severity={vuln.severity} />
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {vuln.packageName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {vuln.packageVersion}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 600, marginBottom: "2px" }}
                        >
                          {formatDate(vuln.updatedAt)}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 500, color: "#A0AEC0" }}
                        >
                          {formatTime(vuln.updatedAt)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          border: "none",
                        }}
                        colSpan={6}
                      >
                        <Collapse
                          in={openPanel === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div style={{ margin: "1rem" }}>
                            <Typography variant="body2">
                              <b>Description:</b> {vuln.description}
                            </Typography>
                            {/* Add more detailed information here */}
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
            ) : (
              <TableRow width="100%">
                <TableCell colSpan={5} align="center" width="100%">
                  <Typography variant="body1">
                    No Vulnerabilities found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default Vulnerabilities;
